export const environment = {
	production: true,
	useEmulators: false,
	firebase: {
		apiKey: 'AIzaSyDpjnlt-rOdx11RTh_uBlaWTJUw4ObFZ3M',
		authDomain: 'whitfields-firefly-live.firebaseapp.com',
		databaseURL: 'https://whitfields-firefly-live.firebaseio.com',
		projectId: 'whitfields-firefly-live',
		storageBucket: 'whitfields-firefly-live.appspot.com',
		messagingSenderId: '527206030209',
	},
	product: 'olympus',
	admin: 'https://firefly.whitfields.co.za',
	client: 'whitfields.co.za',
	olympus: 'olympus.whitfields.co.za',
	googleMapAPI: 'AIzaSyCA_Tid6PhqF_tvPY3K8p6XPJqlbSJ0NNA',
	port: 8008,
	allowedLoginDomains: ['noldor.co.za', 'noltech.co.za', 'whitfields.co.za'],
	useSentry: false,
	region: 'europe-west1',
};
